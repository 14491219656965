<template>
  <footer class="pt-6 pb-3 lg:pt-0">
    <div class="container flex flex-wrap lg:justify-between lg:items-center">
      <div class="w-full lg:w-1/2 lg:pt-8">
        <img
          src="/images/main-logo.png"
          alt="Auto Glass Services"
          class="w-48 mx-auto lg:w-64"
        />

        <div class="pt-6 text-center">
          <h1 class="pb-2 text-xl font-semibold">A Pro Auto Glass Sdn Bhd</h1>
          <div v-if="bahasa_malaysia">
            <p class="text-lg font-semibold">
              <i class="text-gray-800 fas fa-mobile-alt"></i> Telefon Bimbit :
              <a id="cta-bottom" href="tel:+60128480315">012-848 0315</a>
            </p>
          </div>
          <div v-else>
            <p class="text-lg font-semibold">
              <i class="text-gray-800 fas fa-mobile-alt"></i> Mobile  :
              <a id="cta-bottom" href="tel:+60128480315">012-848 0315</a>
            </p>
          </div>
          <div
            v-for="(item, i) in address"
            :key="i"
            class="pt-5 md:max-w-md md:mx-auto"
          >
            <div class="flex md:justify-center">
              <div class="w-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="block w-5 h-5 text-gray-800 fill-current"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M3 13h6v-2H3V1.846a.5.5 0 0 1 .741-.438l18.462 10.154a.5.5 0 0 1 0 .876L3.741 22.592A.5.5 0 0 1 3 22.154V13z"
                  />
                </svg>
              </div>
              <div class="w-3/4 text-left">
                <h1 class="text-base">{{ item.h1 }}</h1>
                <p class="text-sm">{{ item.p }}</p>
              </div>
            </div>
          </div>
          <!-- <p class="py-1">Cheras Services : 12, Pekan Cheras Bt 11, 43200 Cheras</p>
            <p class="py-1">Reko Services : 188 Gf, Jln Langat 2 Tmn Seri Langat 2 Jalan Reko, 43000 Kajang</p>
            <p class="py-1">Segar Services : 16, Jln Manis, Tmn Segar, 56100 Cheras Kuala Lumpur</p>
            <p class="py-1">Damansara Services : 23G, Jalan Pju 5/13, 47810 Kota Damansara, PJ</p> -->
        </div>
      </div>
      <div class="w-full pt-4 md:max-w-md md:mx-auto lg:max-w-full lg:w-1/2">
        <!-- <iframe
          src="https://www.google.com/maps/d/embed?mid=10sz2MHYhNEoOZKJef2mF8PZ3_sd5lbVy"
          width="100%"
          height="400"
        ></iframe> -->
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1H6eHgh-n9iNKk483bqWCe0S3zNx1ft4r"
          width="100%"
          height="450"
        ></iframe>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    bahasa_malaysia: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      address: [
        {
          h1: "A PRO (CHERAS BELAKONG)",
          p: "12, PEKAN CHERAS BATU 11, 43200 CHERAS.",
        },
        {
          h1: "A PRO (SEGAR KL)",
          p: "16, JALAN MANIS, TAMAN SEGAR, 56100 CHERAS, KUALA LUMPUR",
        },
        {
          h1: "AUTO GLASS REKO SERVICES",
          p:
            "188GF, JALAN LANGAT 2, TAMAN SERI LANGAR 2, JALAN REKO, 43000 KAJANG.",
        },
      ],
    };
  },
};
</script>
