<template>
  <div>
    <WaBtn />

    <div class="container">
      <div class="py-4 md:flex md:justify-between md:items-center">
        <div>
          <img src="/images/main-logo.png" alt="Auto Glass Services" class="w-56 mx-auto lg:w-56 md:mx-0" />
        </div>
        <div class="pt-6 pb-4 text-center md:flex md:p-0 lg:text-right">
          <div>
            <h1 class="pr-2 text-xl font-semibold lg:text-2xl">
              Hubungi Kami :
            </h1>
          </div>
          <div class="pt-3 md:pt-0">
            <a id="cta-top" href="tel:+60128480315"
              class="px-3 py-2 text-xl font-semibold rounded-md bg-as-yellow text-black-300 lg:text-2xl">012-848 0315</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Main Banner -->
    <div class="bg-center bg-no-repeat bg-cover bg-adjust" style="background-image: url('/images/main-banner.jpg')">
      <div class="container flex">
        <div class="block md:w-1/4 lg:w-2/3"></div>
        <div class="w-full md:w-3/4">
          <div class="px-5 py-20 md:py-24 lg:py-32">
            <div class="px-4 py-4 text-center bg-black bg-opacity-50 lg:py-8 xl:py-16">
              <h1 class="text-3xl font-bold leading-tight text-white xl:text-5xl md:text-4xl">
                Menghadapi Masalah Dengan Cermin Depan Anda?
              </h1>
              <p class="pt-4 font-normal text-white text-md md:text-xl md:leading-tight lg:text-3xl">
                Pandu kereta anda ke mana-mana bengkel kami atau hubungi kami
                segera dan kami akan membaikinya!
              </p>

              <div class="pt-6 pb-4 md:flex md:items-center md:justify-center">
                <a v-if="!hideEnquiry" id="cta-enquire" href="tel:+60128480315">
                  <div class="w-48 py-2 mx-auto rounded-lg bg-as-yellow">
                    <h1 class="font-semibold texl-lg md:text-xl text-black-300">
                      Tanya Sekarang
                    </h1>
                  </div>
                </a>
                <a v-if="!hideEnquiry" id="wa-btn-banner" href="https://wa.me/+60128480315"
                  class="block pt-2 pl-2 md:pt-0">
                  <div class="w-48 py-2 mx-auto rounded-lg bg-as-yellow">
                    <h1 class="font-semibold text-black-300 texl-lg md:text-xl">
                      <i class="pr-1 text-lg lg:text-xl fab fa-whatsapp"></i>
                      012-8480315
                    </h1>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-8">
      <div class="container">
        <div class="grid grid-cols-1 lg:grid-cols-2">
          <div>
            <img src="/images/about-us.png" alt="Auto Glass Service"
              class="w-3/4 mx-auto md:w-1/2 lg:w-2/3 lg:pt-10 xl:pt-6" />
          </div>
          <div class="text-center lg:text-left">
            <h1 class="text-2xl font-semibold text-gray-800 md:text-3xl">
              Tentang Kami
            </h1>
            <p class="py-5 font-medium text-gray-800 text-md">
              <b>Auto Glass Services Group</b> ialah pakar cermin depan di
              Lembah Klang. Kami pakar dalam semua jenis perkhidmatan penukaran
              cermin depan, pembaikan, pewarnaan dan tuntutan insurans melalui
              kebanyakan syarikat insurans di Malaysia.
              <br />
              <br />Pasukan juruteknik kami terlatih dengan kemahiran
              profesional untuk melaksanakan semua perkhidmatan kami. Kami
              terkenal dengan perkhidmatan kami yang cemerlang, harga yang
              berpatutan dan kompetitif, bertaraf tinggi dengan kemahiran
              berpengetahuan.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Core Services -->
    <div class="py-8 bg-gray-900">
      <div class="container">
        <div class>
          <div class="text-center">
            <h1 class="text-2xl font-semibold text-white md:text-3xl">
              Perkhidmatan Teras Kami
            </h1>
            <div class="max-w-full py-6 text-center text-white md:flex md:flex-wrap md:mx-auto text-md">
              <div class="pb-3 md:w-1/2 lg:w-1/4 xl:pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 mx-auto text-white fill-current"
                  viewBox="0 0 100 100">
                  <path
                    d="M99.485 35.045c-.623-.71-15.596-17.378-49.485-17.378-33.889 0-48.863 16.668-49.485 17.379a2.078 2.078 0 00-.441 1.918l12.5 45.833c.183.67.688 1.201 1.345 1.422a2.085 2.085 0 001.933-.314C15.955 83.824 26.514 76 50 76c2.346 0 4.525.096 6.616.236l-2.526 3.93h-.947a2.083 2.083 0 000 4.167h8.334a2.083 2.083 0 100-4.167h-2.435l2.253-3.504c15.596 1.883 22.77 7.176 22.854 7.242a2.11 2.11 0 001.268.425c.225 0 .45-.034.666-.11a2.078 2.078 0 001.345-1.422l12.5-45.833a2.089 2.089 0 00-.443-1.919zM84.143 79.001c-3.312-1.826-9.977-4.718-20.361-6.205l11.332-17.63H78.9L74.914 69.78a2.085 2.085 0 004.021 1.095l8.492-31.145a2.083 2.083 0 10-4.021-1.094L80.035 51h-6.059a2.08 2.08 0 00-1.752.956L59.16 72.277c-2.842-.258-5.836-.444-9.161-.444-18.599 0-29.545 4.633-34.143 7.168l-11.45-41.98C7.727 33.834 22.093 21.833 50 21.833c27.789 0 42.248 12.008 45.592 15.194L84.143 79.001z" />
                  <path
                    d="M19.682 34.553a2.089 2.089 0 00-2.795.932l-4.167 8.333a2.082 2.082 0 001.862 3.015c.765 0 1.5-.421 1.866-1.152l4.167-8.333a2.085 2.085 0 00-.933-2.795zM28.015 34.553a2.089 2.089 0 00-2.795.932l-8.333 16.667a2.084 2.084 0 003.728 1.865l8.333-16.667a2.087 2.087 0 00-.933-2.797z" />
                </svg>
                <i class="pr-2 fas fa-check"></i> Pemasangan Cermin
              </div>
              <div class="pb-3 lg:w-1/4 md:w-1/2 xl:pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 mx-auto text-white fill-current"
                  viewBox="0 0 100 100">
                  <path
                    d="M42.453 50.146a1.998 1.998 0 00-.291-.702 1.795 1.795 0 00-.244-.297 2.008 2.008 0 00-.998-.533 1.927 1.927 0 00-1.129.111 1.988 1.988 0 00-.635.422 1.942 1.942 0 00-.533.999c-.025.125-.039.254-.039.381a1.955 1.955 0 00.15.749 1.974 1.974 0 00.719.877 2.089 2.089 0 00.703.289c.125.025.254.039.381.039a1.991 1.991 0 001.084-.328 1.96 1.96 0 00.832-2.007z" />
                  <path
                    d="M99.054 38.293l-5.116-3.081a85.39 85.39 0 00-3.844-2.18 9.333 9.333 0 003.21-7.046c0-4.675-6.66-11.389-7.995-12.687a1.953 1.953 0 00-2.723 0c-1.336 1.298-7.996 8.012-7.996 12.687 0 .224.008.445.023.665A85.162 85.162 0 0050 23.032c-15.51 0-30.704 4.212-43.938 12.181L.946 38.294a1.953 1.953 0 00-.867 2.221l13.848 47.334c.151.515.507.945.984 1.19a1.96 1.96 0 001.541.103l7.723-2.729c6.958-2.459 18.259-3.745 25.825-3.745s18.867 1.286 25.825 3.745l7.724 2.729a1.936 1.936 0 001.541-.103c.477-.245.833-.676.984-1.191l13.848-47.333a1.955 1.955 0 00-.868-2.222zM83.947 17.497c2.594 2.792 5.451 6.588 5.451 8.489 0 3.003-2.445 5.447-5.451 5.447s-5.451-2.444-5.451-5.447c0-1.901 2.858-5.698 5.451-8.489zm-1.044 67.274l-5.776-2.041C69.754 80.125 57.899 78.762 50 78.762s-19.754 1.363-27.126 3.969l-5.777 2.041L4.25 40.862l3.826-2.304A81.275 81.275 0 0150 26.938a81.271 81.271 0 0126.23 4.333 9.355 9.355 0 009.471 3.904 81.158 81.158 0 016.222 3.384l3.826 2.304-12.846 43.908z" />
                  <path
                    d="M89.939 41.848a77.158 77.158 0 00-19.063-8.223h-.002A77.579 77.579 0 0050 30.778c-12.958 0-25.787 3.265-37.1 9.44l-.012.007c-.92.5-1.871 1.046-2.828 1.622a1.954 1.954 0 00-.868 2.222l9.874 33.747a1.955 1.955 0 002.525 1.293c8.144-2.878 20.434-4.188 28.407-4.188 7.967 0 20.243 1.308 28.386 4.181a1.958 1.958 0 001.015.088h.002l.025-.004.016-.004.013-.002c.161-.033.319-.088.47-.162l.008-.004.014-.008c.002 0 .002-.001.003-.002.315-.161.589-.41.782-.731l.002-.003.023-.04a.03.03 0 01.004-.008l.016-.029.01-.018a.136.136 0 00.01-.021c.012-.021.021-.042.031-.063l.004-.007a.59.59 0 00.016-.037l.012-.025.008-.019c.027-.069.052-.14.072-.21l9.867-33.723a1.954 1.954 0 00-.868-2.222zM50 34.685a73.76 73.76 0 0118.99 2.475c.47.994 1.132 2.506 1.848 4.182l-23.963 5.677a1.953 1.953 0 00.901 3.801l24.51-5.807a2.91 2.91 0 00.089-.023c.54 1.297 1.056 2.546 1.489 3.599l-37.036 8.458a20.407 20.407 0 00-7.412-10.368c-3.536-2.607-7.888-4.042-12.254-4.042-.161 0-.321.002-.482.005C26.944 37.43 38.417 34.685 50 34.685zm0 36.329c-7.686.001-19.268 1.186-27.75 3.77l-8.178-27.949a16.647 16.647 0 013.089-.291c7.151 0 13.57 4.645 15.841 11.377l-.941.215a1.953 1.953 0 10.87 3.808l21.121-4.824v8.708c0 .742.421 1.42 1.086 1.749l8.887 4.411c-5.072-.66-10.065-.974-14.025-.974zm27.838 3.467l-19.879-9.865v-8.389l21.992-5.022a1.954 1.954 0 00-.87-3.808l-1.357.31a575.242 575.242 0 00-3.784-9.039 73.398 73.398 0 0112.691 5.765l-8.793 30.048z" />
                </svg>
                <i class="pr-2 fas fa-check"></i> Pemasangan Filem Berwarna
              </div>
              <div class="pb-3 lg:w-1/4 md:w-1/2 xl:pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 mx-auto text-white fill-current"
                  viewBox="0 0 100 100">
                  <path
                    d="M99.485 35.045c-.623-.71-15.596-17.378-49.485-17.378-33.889 0-48.863 16.668-49.485 17.379a2.078 2.078 0 00-.441 1.918l12.5 45.833c.183.67.688 1.201 1.345 1.422a2.085 2.085 0 001.933-.314C15.955 83.824 26.514 76 50 76c2.346 0 4.525.096 6.616.236l-2.526 3.93h-.947a2.083 2.083 0 000 4.167h8.334a2.083 2.083 0 100-4.167h-2.435l2.253-3.504c15.596 1.883 22.77 7.176 22.854 7.242a2.11 2.11 0 001.268.425c.225 0 .45-.034.666-.11a2.078 2.078 0 001.345-1.422l12.5-45.833a2.089 2.089 0 00-.443-1.919zM84.143 79.001c-3.312-1.826-9.977-4.718-20.361-6.205l11.332-17.63H78.9L74.914 69.78a2.085 2.085 0 004.021 1.095l8.492-31.145a2.083 2.083 0 10-4.021-1.094L80.035 51h-6.059a2.08 2.08 0 00-1.752.956L59.16 72.277c-2.842-.258-5.836-.444-9.161-.444-18.599 0-29.545 4.633-34.143 7.168l-11.45-41.98C7.727 33.834 22.093 21.833 50 21.833c27.789 0 42.248 12.008 45.592 15.194L84.143 79.001z" />
                  <path
                    d="M28.143 39.505l1.107-.605-.631-1.213-5.465-10.505h.32a5.967 5.967 0 01-1.042-2.792h-4.304c.008.062.005.126.01.188.227.843.253 1.746.082 2.604h.665l6.73 10.769-9.762 6.395-1.238.812 1.27.629 18.827 9.327-16.761-10.029 10.192-5.58zM49.355 21.683h1.197c-.395-.815-.63-1.743-.671-2.793H46.25a5.772 5.772 0 01-.794 2.792h.875l-1.319 7.52-.071.404.164.225 5.907 8.141-4.332-8.622 2.675-7.667zM79.395 27.182a16.286 16.286 0 01-1.43-1.255 4.742 4.742 0 01-1.035-1.537h-5.088c.1.385.158.777.158 1.168a4.89 4.89 0 01-.29 1.625h.536l2.979 1.568-9.604 9.589-.697.696.85.36 11.822 5.009L67.2 38.711l11.352-9.263 1.441-1.177-1.646-1.09h1.048z" />
                </svg>
                <i class="pr-2 fas fa-check"></i> Tuntutan Insurans Cermin Depan
              </div>
              <div class="pb-3 lg:w-1/4 md:w-1/2 xl:pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 mx-auto text-white fill-current"
                  viewBox="0 0 100 100">
                  <path d="M45.655 52.916h-5.277a1.543 1.543 0 000 3.088h5.277a1.543 1.543 0 100-3.088z" />
                  <path
                    d="M77.154 11.012h-22.77V8.074a1.546 1.546 0 00-1.942-1.492l-30.851 8.227a1.544 1.544 0 00-1.146 1.492v4.986a1.543 1.543 0 103.087 0v-3.8l27.763-7.404v82.49L23.532 87.02V26.489a1.543 1.543 0 00-3.087 0v61.798c0 .735.52 1.369 1.241 1.513l30.85 6.171a1.54 1.54 0 001.849-1.514V91.52h14.224a1.544 1.544 0 100-3.088H54.385v-4.403h15.277c.854 0 1.543-.691 1.543-1.544V20.046c0-.853-.689-1.543-1.543-1.543H54.385v-4.404H75.61v74.333h-2.202a1.543 1.543 0 100 3.087h3.746c.853 0 1.543-.691 1.543-1.544v-77.42c0-.852-.69-1.543-1.543-1.543zM68.119 21.59v59.35H54.385V21.59h13.734z" />
                </svg>
                <i class="pr-2 fas fa-check"></i> Perkhidmatan dari Door to Door
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Product Services -->
    <div class="py-8">
      <div class="container">
        <div>
          <h1 class="pb-4 text-2xl font-semibold text-center text-gray-800 lg:text-3xl">
            Perkhidmatan Produk
          </h1>
          <div class="text-center">
            <img src="/images/product.jpg" class="mx-auto md:w-2/3 lg:w-2/4" />
            <p class="py-2 font-medium text-gray-800 lg:text-lg text-md">
              Jangan risau! Kami telah melindungi anda dengan baik. Kami
              menyediakan semua jenis perkhidmatan produk kaca automotif!
            </p>
          </div>
          <a id="cta-product" href="tel:+60128480315">
            <div class="w-4/5 p-3 mx-auto rounded-lg bg-as-yellow md:w-2/4 lg:w-1/3 xl:w-1/4">
              <h1 class="font-bold text-center">
                Hubungi Kami Sekarang <br> 012-848 0315
              </h1>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- insurance panel -->
    <div class="py-4">
      <div class="container">
        <div>
          <h1 class="pb-8 text-2xl font-semibold text-center text-gray-800 lg:text-3xl">
            Panel Insurans Kami
          </h1>
          <div class="flex flex-wrap justify-center">
            <div v-for="(item, i) in client" :key="i" class="w-1/3 p-1 md:w-1/5 lg:w-1/6">
              <img :src="item.image" :alt="item.alt" class />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Crew Services -->
    <div class="py-5">
      <div class="container">
        <div class="py-3">
          <h1 class="text-xl font-semibold text-center text-gray-800 md:text-3xl">
            Kenapa Pilih Kami
          </h1>
        </div>
        <carousel :autoplay="true" :loop="true" :dots="true" :autoplayTimeout="6000" :responsive="{
                  0: { items: 1, nav: false, dots: false },
                  768: { items: 3, nav: false },
                  1024: { items: 4, nav: false },
                }">
          <div class="md:px-1" v-for="image in gallery" :key="image">
            <img :src="'/images/' + image + '.jpg'" class="py-1" />
          </div>
        </carousel>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-8 bg-gray-400">
      <div class="container">
        <div>
          <h1 class="pb-4 text-2xl font-semibold text-center text-black md:text-3xl lg:pb-8">
            Mengapa Pilih Auto Glass Services!
          </h1>
        </div>

        <div class="flex flex-wrap mt-8 md:justify-center">
          <div v-for="(item, i) in choose_us" :key="i" class="w-1/2 p-1 md:w-1/3 lg:w-1/4">
            <div
              class="px-3 py-5 text-gray-700 transition duration-200 ease-linear transform bg-white rounded-lg hover:text-auto-yellow hover:shadow-lg">
              <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
              <div class="h-10 mt-3 text-center lg:h-auto">
                <h1 class="text-sm font-medium leading-tight">
                  {{ item.alt }}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <h1 class="w-32 py-2 mx-auto font-medium text-center text-gray-800 bg-as-yellow">
            <a href="https://wa.me/+60128480315">Tanya Kami</a>
          </h1>
        </div>
      </div>
    </div>

    <!-- our process -->
    <div class="py-8">
      <div class="">
        <div class="container">
          <div class="mb-8 text-center">
            <h1 class="text-2xl font-semibold text-gray-800">
              Proses Kami
            </h1>
            <h1 class="text-3xl font-semibold text-gray-800">Prosesnya Mudah!</h1>
          </div>
          <!-- timeline -->
          <div class="relative w-full lg:max-w-3xl lg:mx-auto timeline">
            <div v-for="(item, i) in our_process" :key="i" class="mb-10">
              <!-- step 1 -->
              <div class="md:h-28">
                <div
                  class="w-40 text-base text-center text-white bg-gray-800 rounded-full md:left-1/2 md:mt-6 md:absolute"
                  :class="item.margin">
                  <div class="inline-flex items-center p-1">
                    <div class="relative px-2">
                      <h1 class="font-bold">{{ item.step }}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <!-- content -->
              <div class="mt-6 md:flex md:items-center md:justify-center">
                <div class="pb-6 md:pb-0 md:w-1/2 md:px-4" :class="item.order">
                  <h1 class="pb-2 text-3xl font-bold leading-tight text-auto-yellow">
                    {{ item.h1 }}
                  </h1>
                  <p class="pt-2 text-sm text-gray-800">
                    {{ item.p }}
                  </p>
                </div>
                <div class="md:w-1/2">
                  <img :src="item.image" alt="" class="w-2/3 mx-auto md:w-3/4" />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h1 class="w-32 py-2 mx-auto font-medium text-center text-gray-800 bg-as-yellow">
              <a href="https://wa.me/+60128480315">Tanya Kami</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- our branch -->
    <div class="bg-as-yellow">
      <div class="container">
        <div class="flex flex-wrap py-3 lg:py-5">
          <!-- <div class="w-full pb-4 lg:w-1/3 xl:w-1/2"></div> -->
          <div class="w-full px-3">
            <h1 class="py-3 text-2xl font-semibold text-center text-black lg:text-2xl">
              CAWANGAN KAMI
            </h1>
            <div class="md:flex md:flex-wrap">
              <div class="pb-4 md:w-1/2 md:px-2 lg:w-1/3" v-for="(item, i) in ourstore" :key="i">
                <img :src="item.image" :alt="item.alt" class="p-1 mx-auto lg:w-full xl:w-auto" />
                <h1 class="pt-2 font-bold text-center">{{ item.h1 }}</h1>
                <p class="text-center">{{ item.p }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Door to Door -->
    <div class="py-6 bg-gray-100">
      <div class="container">
        <div class="lg:flex lg:flex-wrap lg:items-center">
          <div class="lg:w-1/2 lg:px-4">
            <img src="/images/doortodoor.png" alt="door to door services" class="mx-auto md:w-2/3 lg:w-full" />
          </div>
          <div class="py-3 lg:w-1/2">
            <h1 class="text-xl font-semibold text-center text-gray-800 md:text-3xl">
              Door to Door Service
            </h1>
            <div class="mt-4 text-center text-gray-800">
              <!-- <ul class="list-disc list-inside">
                <li>Authorised Insurance Claim</li>
                <li>Windscreen/ Door Glass/ Rear Glass/ Sunroof Replace</li>
              </ul> -->
              <h1 class="text-xl font-bold leading-tight">
                Pembaikan Cermin Depan di Rumah Anda!
              </h1>
              <p class="mt-2 text-gray-600">
                Duduk, berehat dan serahkan sahaja pembaikan kepada kami.
              </p>
            </div>
            <div class="pt-5 md:flex md:flex-wrap md:justify-center">
              <div class="pb-3 text-center md:w-1/3">
                <img src="/images/door-1.png" alt="Save time" class="w-32 mx-auto" />
                <p class="pt-4 text-base font-bold text-gray-900">Menjimatkan masa</p>
              </div>
              <div class="pb-3 text-center md:w-1/3">
                <img src="/images/door-2.png" alt="Convenience" class="w-32 mx-auto" />
                <p class="pt-4 text-base font-bold text-gray-900">
                  Keselesaan
                </p>
              </div>
              <div class="pb-3 text-center md:w-1/3">
                <img src="/images/door-3.png" alt="Easy" class="w-32 mx-auto" />
                <p class="pt-4 text-base font-bold text-gray-900">Mudah</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- tinted installation -->
    <div class="py-8 bg-asg-gray">
      <div class="container">
        <h1 class="pb-4 text-xl font-semibold text-center text-gray-800 md:text-2xl lg:text-3xl">
          Pemasangan Filem Berwarna
        </h1>
        <div class="flex flex-wrap">
          <div v-for="(item, i) in tinted" :key="i" class="w-1/2 md:w-1/4">
            <img :src="item.image" :alt="item.alt" class="p-1 lg:w-full xl:w-auto" />
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <MainFooter bahasa_malaysia="true" />
    <Copyright />
  </div>
</template>

<script>
import WaBtn from "@/components/WaBtn.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue"
import Copyright from "@/components/Copyright.vue"
import carousel from "vue-owl-carousel";


export default {
  components: {
    WaBtn,
    MainHeader,
    MainFooter,
    Copyright,
    carousel
  },
  data() {
    return {
      client: [
        { image: "/images/panels/panel-1.jpg", alt: "ACE" },
        { image: "/images/panels/panel-2.jpg", alt: "AIA" },
        { image: "/images/panels/panel-3.jpg", alt: "Allianz" },
        { image: "/images/panels/panel-4.jpg", alt: "AXA" },
        // { image: "/images/panels/panel-5.jpg", alt: "BERJAYA SOMPO" },
        { image: "/images/panels/panel-6.jpg", alt: "CHUBB" },
        { image: "/images/panels/panel-7.jpg", alt: "ETIQA" },
        { image: "/images/panels/panel-8.jpg", alt: "HONG LEONG" },
        { image: "/images/panels/panel-9.jpg", alt: "LIBERTY" },
        { image: "/images/panels/panel-10.jpg", alt: "MPI" },
        { image: "/images/panels/panel-11.jpg", alt: "MSIG" },
        { image: "/images/panels/panel-12.jpg", alt: "Pacific Insurance" },
        { image: "/images/panels/panel-13.jpg", alt: "RHB" },
        { image: "/images/panels/panel-14.jpg", alt: "Takaful Ikhlas" },
        { image: "/images/panels/panel-15.jpg", alt: "TAKAFUL MALASIA" },
        { image: "/images/panels/panel-16.jpg", alt: "Tokio Marine" },
        { image: "/images/panels/panel-17.jpg", alt: "ZURICH" },
        { image: "/images/panels/panel-18.jpg", alt: "Progressive" },
        {
          image: "/images/panels/panel-19.jpg",
          alt: "Pacific & Orient Insurance",
        },
      ],
      choose_us: [
        { image: "/images/choose-1.png", alt: "Kenderaan Pelepasan Hari Yang Sama" },
        { image: "/images/choose-2.png", alt: "Door to Door Service" },
        { image: "/images/choose-3.png", alt: "Perkhidmatan Ambil" },
        { image: "/images/choose-4.png", alt: "Pusat Perkhidmatan Filem Berwarna" },
        { image: "/images/choose-5.png", alt: "Insurans diterima" },
        { image: "/images/choose-6.png", alt: "100% Cermin Depan Asli" },
        {
          image: "/images/choose-7.png",
          alt: "Semua kenderaan bermotor diterima",
        },
        { image: "/images/choose-8.png", alt: "Waranti 5 Tahun Tersedia" },
      ],
      our_process: [
        {
          margin: 'md:ml-[250]',
          step: 'Langkah 1',
          h1: "Buat temujanji",
          p: 'Hubungi Perunding Pakar Kami dan aturkan temu janji',
          image: '/images/process-1.jpg'
        },
        {
          margin: 'md:ml-[300]',
          step: 'Langkah 2',
          h1: "Door to Door Service",
          p: 'Door to Door Service atau Kunjungi Cawangan/Perkhidmatan Kami',
          image: '/images/process-2.jpg',
          order: 'order-2'
        },
        {
          margin: 'md:ml-[250]',
          step: 'Langkah 3',
          h1: "Pasang cermin depan",
          p: 'Ia boleh dilakukan di mana-mana sahaja! Perkhidmatan kami menjadikannya Mudah untuk anda dan keluarga anda',
          image: '/images/process-3.jpg'
        },
        {
          margin: 'md:ml-[300]',
          step: 'Langkah 4',
          h1: "Kutipan Bayaran",
          p: 'Menerima perbankan dalam talian, kad kredit, kad debit dan banyak lagi!',
          image: '/images/process-4.jpg',
          order: 'order-2'
        },
        {
          margin: 'md:ml-[250]',
          step: 'Langkah 5',
          h1: "Perkhidmatan Tuntutan Insurans",
          p: 'Tuntutan insurans cermin depan tanpa kerumitan',
          image: '/images/process-5.jpg'
        },
      ],
      ourstore: [
        {
          image: "/images/branch-1.jpg",
          h1: "A PRO (CHERAS BELAKONG)",
          p: "NO 12, PEKAN CHERAS, BATU 11, 43200 CHERAS",
        },
        {
          image: "/images/branch-2.jpg",
          h1: "A PRO (SEGAR KL)",
          p: "NO 16, JALAN MANIS, TAMAN SEGAR, 56100 CHERAS, KUALA LUMPUR",
        },
        {
          image: "/images/branch-4.jpg",
          h1: "AUTO GLASS REKO SERVICES",
          p:
            "NO 188GF, JALAN LANGAT 2,TAMAN SERI LANGAT 2, JALAN REKO, 43000 KAJANG",
        },
      ],
      gallery: [
        'gallery-1',
        'gallery-2',
        'gallery-3',
        'gallery-4',
        'gallery-5',
        'gallery-6',
        'gallery-7',
        'gallery-8',
        'gallery-9',
        'gallery-10',
        'gallery-11',
        'gallery-12',
        'gallery-13',
        'gallery-14',
        'gallery-15',
        'gallery-16',
      ],
      tinted: [
        { image: "/images/tinted-4.jpg", alt: "tinted installation" },
        { image: "/images/tinted-2.jpg", alt: "tinted installation" },
        { image: "/images/tinted-1.jpg", alt: "tinted installation" },
        { image: "/images/tinted-3.jpg", alt: "tinted installation" },
      ],
    };
  },
};
</script>

<style>
@media (min-width: 768px) {
  .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background-color: #2d3748;
    left: 50%;
    margin-left: -1.5px;
  }
}
</style>
